import { useTheme } from "@mui/material";
import { FHBreadcrumbProps } from "../../components/SemanticTheme/FHBreadcrumb";
import React, { useEffect, useState } from "react";
import { createPathFromUrl } from "../../utils";
import { Footer } from "../../components/SemanticTheme/Footer";
import FHButton from "../../components/SemanticTheme/FHButton";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { SocialIconButton } from "../../components/SemanticTheme/SocialIconButton";
import RichText from "./RichText";
import useNewsLetterSignUpService from "../../services/newsletter-signup-service";
import { withContent } from "../../utils/TemplateWithContent";
import {
  useSiteSettingsSelector,
  useBreadcrumbSelector,
} from "@/store/selectors";
import { FHNextImage } from "../../components/SemanticTheme/FHNextImage";
import { updateAbsoluteLink } from "@components/Utils";

export const FooterComponent = (props: any) => {
  const { homePageIcon, loading: siteSettingsLoading } =
    useSiteSettingsSelector();
  const { breadcrumb, loading: breadcrumbLoading } = useBreadcrumbSelector();

  const { success, errors, loading, newsletterSignUp } =
    useNewsLetterSignUpService();
  const theme = useTheme();
  const [fhBreadcrumbProps, setFHBreadcrumbProps] = useState<
    FHBreadcrumbProps | undefined
  >(undefined);

  const primaryActionLinks = props?.PrimaryFooterLinks;
  const secondaryActionLinks = props?.SecondaryFooterLinks;
  const bgImgUrl = props?.BackgroundImage?.data?.attributes?.url;
  const footerCtaText = props?.PrimaryCallToActionText as string;
  const footerCtaUrl = updateAbsoluteLink(
    props?.PrimaryCallToActionReference?.data?.attributes?.PageRoute,
  );
  const breadcrumbSeparatorIcon = props?.BreadcrumbSeparatorIcon
    ? props?.BreadcrumbSeparatorIcon?.data?.attributes?.url
    : undefined;
  const breadcrumbTheme = props?.BreadcrumbTheme
    ? (props?.BreadcrumbTheme as string) === "Light"
      ? "light"
      : // eslint-disable-next-line no-constant-condition
        "Dark"
        ? "dark"
        : undefined
    : undefined;
  const maxItemsOnMobile = props?.BreadcrumbMaxItemsOnMobile
    ? (props?.BreadcrumbMaxItemsOnMobile as number)
    : undefined;

  //Breadcrumb
  useEffect(() => {
    if (breadcrumbLoading && siteSettingsLoading) return;
    const homePageIconUrl = homePageIcon?.url ?? undefined;
    const fhBreadcrumbs = breadcrumb.map((page, index) => {
      const isHomePage: boolean =
        createPathFromUrl(page?.url as string) === "/";
      const isCurrentPage: boolean = breadcrumb.length - 1 === index;
      return {
        label: isHomePage ? undefined : (page?.label as string),
        link: isCurrentPage
          ? undefined
          : createPathFromUrl(page?.url as string),
        iconUrl: isHomePage ? homePageIconUrl : undefined,
      };
    });
    setFHBreadcrumbProps(
      fhBreadcrumbs && fhBreadcrumbs.length
        ? {
            breadcrumbs: fhBreadcrumbs,
            maxItemsOnMobile: maxItemsOnMobile,
            separator: breadcrumbSeparatorIcon ? (
              <FHInlineSvg src={breadcrumbSeparatorIcon} size="20px" />
            ) : undefined,
            theme: breadcrumbTheme,
          }
        : undefined,
    );
  }, [breadcrumb, homePageIcon]);

  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const newsLetterSubmit = () => {
    newsletterSignUp({
      variables: {
        request: {
          email: email ? email : "",
          forenames: name ? name : "",
          sourceType: "Web",
          sourceDetail: "Page Footer",
        },
      },
    });
  };

  const socialItems = props?.SocialMediaReferences;
  const footerWhichLogo = props?.experiments?.footerWhichLogo;
  return (
    <Footer
      footerCtaLabel={footerCtaText}
      footerCtaUrl={footerCtaUrl}
      Logo={() => (
        <FHNextImage
          src={props?.LogoImage?.data?.attributes?.url}
          alt="Forest Holidays"
          width={150}
          height={56}
          loading="lazy"
        />
      )}
      title={props?.Title as string}
      subtitle={props?.SubTitle as string}
      firstName={props?.FirstNameLabel as string}
      email={props?.EMailLabel as string}
      links={[
        primaryActionLinks &&
          primaryActionLinks.map((link) => ({
            label: link?.LinkText,
            url:
              updateAbsoluteLink(link?.LinkURL?.data?.attributes?.PageRoute) ??
              "/",
          })),
        secondaryActionLinks &&
          secondaryActionLinks.map((link) => ({
            label: link?.LinkText,
            url:
              updateAbsoluteLink(link?.LinkURL?.data?.attributes?.PageRoute) ??
              "/",
          })),
      ]}
      SubscribeButton={() => (
        <FHButton
          fhStyle="ghost"
          style={{
            padding: theme.spacing(1, 0.6),
            height: "50px",
          }}
          disabled={!name && !email}
          onClick={newsLetterSubmit}
          fullWidth
        >
          {props?.SubscribeButtonLabel as string}
        </FHButton>
      )}
      AwardsText={() => (
        <RichText
          contentId="footerText"
          contentValue={props?.FooterText}
          color="themeWhite"
        />
      )}
      AwardsImage={() =>
        props?.AwardsImage?.data?.attributes?.url ? (
          <FHNextImage
            src={
              !!footerWhichLogo
                ? "https://assets.forestholidays.co.uk/damprodblob/assets/awards_footer_July_24_247fc55511.png"
                : props?.AwardsImage?.data?.attributes?.url
            }
            alt="Awards"
            layout="fill"
            loading="lazy"
            objectFit="contain"
            objectPosition={0}
          />
        ) : undefined
      }
      SocialIcons={() => (
        <>
          {socialItems?.data?.map((socialContent, idx) => (
            <SocialIconButton
              key={`socialItem-icon-${idx}`}
              type={socialContent?.attributes?.Platform}
              alt={socialContent?.attributes?.Description as string}
              url={socialContent?.attributes?.Url as string}
              color="white"
            />
          ))}
        </>
      )}
      newsletterComplete={success}
      newsletterLoading={loading}
      errors={errors}
      setEmail={setEmail}
      setFirstName={setName}
      bgImgUrl={bgImgUrl}
      signUpConfirmationMessage={props?.SignUpConfirmationMessage as string}
      breadcrumbProps={fhBreadcrumbProps}
      reduceFooterSpacing={Boolean(props?.experiments?.reduceFooterSpacing)}
      footerAlignLogoandText={Boolean(
        props?.experiments?.footerAlignLogoandText,
      )}
    />
  );
};

const FooterComponentTemplate = ({ content }): JSX.Element => {
  const pageImgQuality = content?.pageImgQuality;
  return withContent(FooterComponent, content, undefined, pageImgQuality);
};
export default FooterComponentTemplate;
